<script setup>
import { ref } from 'vue';
import { useMeta } from 'vue-meta';

const listItems = ref([
  {
    copy: "Innovative display technology to showcase Lincoln's warm, rich colors, indulgent materials, and the entire model lineup - including Black Label*",
  },
  {
    copy: 'Thoughtfully designed 360-degree views of the vehicle interior and exterior with effortless pinch-and-zoom capability',
  },
  {
    copy: 'Displayed in a virtual environment with high fidelity projection that complements the Lincoln Vitrine aesthetic and elevates remote purchase experiences',
  },
  {
    copy: 'Intuitive navigation on an iPad app makes it simple to explore, build, and compare models visually - together with your Clients',
  },
  {
    copy: 'Effortless cloud-connected updates to ensure the latest product information and experience',
  },
  {
    copy: 'Dedicated support team &mdash; program headquarters',
  },
]);

useMeta({ title: 'Home' });
</script>
<template>
  <div class="home">
    <img
      alt="hands holding iPad"
      src="../assets/img/hands-holding-iPad.jpg"
      class="w-[31.75rem] mx-auto mb-2.5"
    />
    <div class="max-w-[31.75rem] mx-auto">
      <p class="my-4">
        Lincoln Client Experience enters the next level of luxury with the
        all-new, fully immersive, 360-degree, custom visualization experience.
      </p>
      <p class="my-4">Featuring &mdash;</p>
      <ul class="list-inside space-y-3">
        <li
          v-for="(listItem, index) in listItems"
          :key="index"
          class="flex items-start"
        >
          <div class="mr-2 font-bold">-</div>
          <div v-html="listItem.copy"></div>
        </li>
      </ul>
      <p class="my-4 text-xs font-medium italic tracking-tight">
        *Black Label vehicles accessible to Black Label certified retailers
      </p>
      <h2 class="font-semibold text-lg text-center tracking-tight mt-10 mb-12">
        Enrollment &amp; Release - Coming in Q1 2023!
      </h2>
      <a
        href="https://app.smartsheet.com/b/form/29709bd29e0d4513bcc185dab921674a"
        class="
          block
          max-w-max
          bg-[#3e4e55]
          font-black
          text-white text-lg
          tracking-wider
          py-3
          px-5
          text-center
          uppercase
          mx-auto
          border-b-2 border-[#f26147]
          rounded-sm
          shadow-md
        "
        >Register Interest</a
      >
    </div>
  </div>
</template>
