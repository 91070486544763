<template>
  <div class="load-dots">
    <div class="dot-pulse"></div>
  </div>
</template>

<script>
export default {
  name: 'LoadDots',
};
</script>

<style lang="scss" scoped>
.load-dots {
  display: flex;
  align-items: center;
  justify-content: center;

  .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #324047;
    color: #324047;
    box-shadow: 9999px 0 0 -5px #324047;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: 0.25s;

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #324047;
      color: #324047;
    }

    &::before {
      box-shadow: 9984px 0 0 -5px #324047;
      animation: dotPulseBefore 1.5s infinite linear;
      animation-delay: 0s;
    }

    &::after {
      box-shadow: 10014px 0 0 -5px #324047;
      animation: dotPulseAfter 1.5s infinite linear;
      animation-delay: 0.5s;
    }
  }

  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #324047;
    }
    30% {
      box-shadow: 9984px 0 0 2px #324047;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #324047;
    }
  }

  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #324047;
    }
    30% {
      box-shadow: 9999px 0 0 2px #324047;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #324047;
    }
  }

  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #324047;
    }
    30% {
      box-shadow: 10014px 0 0 2px #324047;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #324047;
    }
  }
}
</style>
