<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content
        ? `${content} | Lincoln Personalization Studio`
        : `Lincoln Personalization Studio`
    }}</template>
  </metainfo>
  <div class="container mx-auto pb-12 min-h-screen">
    <router-link to="/">
      <img
        alt="Lincoln Personalization Studio logo"
        src="./assets/img/header-logo.jpg"
        class="mx-auto mb-4"
      />
    </router-link>
    <div class="px-2">
      <router-view />
    </div>
  </div>
</template>
<script>
import { useMeta } from 'vue-meta';

export default {
  computed: {
    notConfirmationPage() {
      return this.$route.path !== '/confirmation';
    },
  },
  setup() {
    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true },
    });
  },
};
</script>
