<script setup>
import { useMeta } from 'vue-meta';

useMeta({ title: 'Confirmation' });
</script>
<template>
  <div class="confirmation">
    <div class="space-y-3 max-w-[31.75rem] mx-auto">
      <p>Thank you for your interest in the Lincoln Personalization Studio.</p>
      <p>
        Early in the new year, our Lincoln Team will be in touch with enrollment
        information.
      </p>
    </div>
  </div>
</template>
