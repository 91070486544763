<template>
  <div class="register relative">
    <transition
      enter-active-class="transition-opacity duration-300 ease-in"
      enter-from-class="opacity-0"
      leave-active-class="transition-opacity duration-200 ease-out"
      leave-to-class="opacity-0"
    >
      <div v-show="isLoading" class="absolute w-full text-center">
        <load-dots />
      </div>
    </transition>
    <iframe
      height="1000"
      src="https://app.smartsheet.com/b/form/29709bd29e0d4513bcc185dab921674a"
      title="myFrame"
      class="w-full max-w-[31.75rem] mx-auto"
      @load="onMyFrameLoad()"
    ></iframe>
  </div>
</template>
<script>
import { useMeta } from 'vue-meta';
import LoadDots from '@/components/LoadDots.vue';

export default {
  components: {
    LoadDots,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    onMyFrameLoad() {
      this.isLoading = false;
    },
  },
  setup() {
    useMeta({ title: 'Register' });
  },
};
</script>
